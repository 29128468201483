.about-image {
  margin-top: -110px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .about-image {
    margin-top: 10px;
    width: 100%;
  }
}
