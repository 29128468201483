h1 {
  font-size: 38px;
}
h2 {
  font-size: 34px;
}
p {
  color: #666666;
}

.navbar-collapse {
  flex-grow: 0;
}

.nav-link {
  background: #f16623;
  margin-left: 2px;
  margin-bottom: 1px;
  color: white;
  font-size: 14px;
  padding-bottom: 10px;

  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

button {
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.nav-link:hover,
.nav-link:focus {
  color: white;
  background: #c75e2c;
}

.navbar-nav .nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.navbar-light .navbar-nav .nav-link {
  color: white;
  font-size: 14px;
}

.homePage {
/*  min-height: 490px;
  height: calc(100vh - 90px);*/
  /* overflow: hidden; */
  /* border-bottom: 130px solid #f16623; */
}

.homePage .orange-bar {
/*  position: absolute;*/
  width: 100%;
  bottom: 0;
}

.home-banner {
  height: calc(80vh - 160px);
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  flex-direction: column;
}

.smallScreens {
  display: none;
}

.page-body {
  margin-top: 90px;
}
.detail-page-nav {
  display: flex;
  justify-content: space-between;
}
.heading-cover {
  background-color: #f16623;
  padding: 25px;
  position: relative;
  margin-top: -1%;
/*  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.52);
  -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.52);
  -moz-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.52);*/
}
.heading-container h1 {
  color: white;
  /* font-weight: 600;
  font-size: 50px; */
}
.partner-content-area {
  width: 90%;
  margin: 0 auto;
}

.text-area p,
.page-text-area p {
  font-size: 14px;
  color: #666666;
  /* margin-bottom: 40px;
  font-weight: 300; */
}
.services-card {
  background-color: #fffaf8;
  width: 30%;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: 0px 1px 10px 3px rgba(0, 0, 0, 0.38);
  -webkit-box-shadow: 0px 1px 10px 3px rgba(0, 0, 0, 0.38);
  -moz-box-shadow: 0px 1px 10px 3px rgba(0, 0, 0, 0.38);
}
.services-card-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f16623;
  height: 78px;
  color: white;
  padding: 0 26px;

/*  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.52);
  -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.52);
  -moz-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.52);*/
}
.services-card-header h5 {
  font-size: 22px;
  text-align: center;
}
.services-card-body {
  padding: 30px 20px;
  text-align: center;
  color: #f26624;
}

.services-cards {
  width: 90%;
  margin: 0 auto;
}

.orange-bar {
  background-color: #f16623;
/*  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.52);
  -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.52);
  -moz-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.52);*/
  padding: 35px 0;
  margin-top: 170px;
}

.orange-bar p {
  font-size: 20px;
  color: white;
  text-align: center;
}
.burgerImg {
  width: 100%;
  position: absolute;
  margin-top: -125px;
}
.sampleStepsheading {
  font-size: 45px;
  color: #f16623;
  font-weight: bold;
  margin-top: 100px;
  line-height: 55px;
}

.stepsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.steps {
  width: 300px;
}
.circle {
  width: 200px;
  height: 200px;
  background: white;
  border-radius: 50%;
  box-shadow: 0px 0px 10px #000;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.circle h3 {
  color: #f16623;
  font-size: 95px;
  font-weight: bold;
}
.steptext {
  color: white;
  font-size: 30px;
  padding: 15px;
  text-align: center;
}

button.site-btns {
  background: #f16623;
  color: white;
  font-size: 20px;
  padding: 5px 32px 8px;
}
.image100 {
  width: 100%;
}

textarea {
  border:1px solid #f16623;
  padding: 15px;
  width: 100%;
}

input {
  border:1px solid #f16623;
  padding: 5px 15px;
  border-radius: 2px;
  width: 100%;
}
.input:focus{

  outline: none !important;
  border:1px solid #f16623;

}

input[type="file"] {
  width: 100%;
}

label {
  font-size: 14px;
}

.pages-text-area p,
.pages-text-area ul li {
  font-size: 14px;
  color: #666666;
  /* margin-bottom: 20px;
  font-weight: 300; */
}

.tempMenu {
  display: flex;
  justify-content: space-around;
  width: auto;
  margin-bottom: 20px;
}
.aboutImage {
  max-height: 100px;
  max-width: 100%;
  min-height: 60px;
  width: auto;
  float: left;
}
.home-banner img,
.store-links img {
  width: 100%;
}

.homePage h1,
.homePage h2 {
  color: #f16623;
  font-weight: 700;
  /* font-size: 40px; */
}
.homePage h1 {
  /* font-size: 45px; */
}
.download-app {
  color: #f16623;
  font-weight: 300;
  font-size: 18px;
}

.site-logo {
  width: 130px;
}
header ul.nav li {
  background: #f16623;
  margin-left: 2px;
  margin-bottom: 1px;
}
header ul li a {
  font-size: 14px;
  color: #ffffff;
  padding-left: 30px;
}
header ul li:hover {
  background: #aa4411;
  transition: all 0.2s ease-in-out;
}
header ul li:hover a {
  color: #ffffff;
}
nav.border-bottom {
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.52);
  -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.52);
  -moz-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.52);
  margin-bottom: 50px;
}

/* Footer Start*/
.navLinks {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.navLinks a {
  color: #666666;
  text-align: center;
  padding: 0 15px;
}
.socialNav {
  justify-content: center;
  text-align: center;
}
.socialNav a {
  width: 40px;
  margin-left: 5px;
  margin-right: 5px;
}
.socialNav a img {
  width: 40px;
}
/* Footer End */

@media screen and (max-width: 1440px) {
}
@media screen and (max-width: 1280px) {
}
@media screen and (max-width: 1040px) {
}
@media screen and (max-width: 991px) {
  .burgerImg {
    margin-top: 30px;
  }

  .burgerbar {
    display: none;
  }

  .services-cards {
    width: 100%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 767px) {
  #responsive-navbar-nav {
    margin-top: 10px;
  }
  .home-banner {
    height: auto !important;
  }
  .bigScreens {
    display: none;
  }
  .smallScreens {
    display: block;
  }
  .page-body {
    margin-top: 60px;
  }

  h1 {
    font-size: 28px;
  }
  h2 {
    font-size: 22px;
  }

  .site-logo {
    width: 90px;
  }
  .partner-content-area {
    width: 100%;
  }
  .sampleStepsheading {
    margin-top: 0;
    font-size: 38px;
    text-align: center;
  }
  .orange-bar {
    margin-top: 0;
  }
  .burgerImg {
    margin-top: 0;
    position: relative;
  }
  .burgerbar {
    display: none;
  }

  .services-card {
    width: 300px;
  }
}
@media screen and (max-width: 680px) {
  .steps {
    width: 100%;
  }
  .services-card {
    width: 80%;
  }
}
@media screen and (max-width: 527px) {
}
@media only screen and (max-width: 425px) {
}
@media only screen and (max-width: 375px) {
}
@media only screen and (max-width: 320px) {
}
