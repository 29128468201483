.site-btns {
  padding: 7px 61px;
  box-shadow: 2px 2px 10px #8b8787;
}

.spinnerContainer{
  position: relative;
}
.spinner{
  position: absolute;
  left: 45%;
  top:10%;
  z-index: 1000;
}
.instruction{
  color: #f16623;
}
@media screen and (max-width: 680px) {
  .about-image {
    margin-top: 10px;
    width: 100%;
  }
}
